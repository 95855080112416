<script lang="ts" setup>
import type { HTMLAttributes } from "vue";
import type { Label, LabelProps } from "radix-vue";
import { useFormField } from "./useFormField";

const props = defineProps<LabelProps & { class?: HTMLAttributes["class"] }>();

const { formItemId } = useFormField();
</script>

<template>
  <Label :class="props.class" :for="formItemId">
    <slot />
  </Label>
</template>
